const menus = [{
	"id": 100,
	"authName": "仪表盘",
	"path": "home",
	"children": [],
	"order": null
},
{
	"id": 2,
	"authName": "农资商城管理",
	"path": "goods",
	"children": [
	{
		"id": 21,
		"authName": "农资商城订单列表",
		"path": "orders_shop",
		"children": [],
		"order": null
	}
	],
	"order": 3
}
];

export {
	menus
}